import React, { useEffect } from "react";
import axios from "axios";
import Selects from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import ReactImageProcess from "react-image-process";
import { localApi } from "utils/imgApi";
import { catApi } from "utils/imgApi";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { AiOutlineFolderView } from "react-icons/ai";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styHoever from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import logo from "assets/img/apple-admin-icon.png";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyl = makeStyles(styDrop);
const useHoever = makeStyles(styHoever);
const useStyleSweet = makeStyles(styleSweet);

export default function ViewImage(props) {
  const [category, setCategory] = React.useState([]);
  const [categoryMM, setCategoryMM] = React.useState([]);
  const [itemProd, setItemProd] = React.useState([]);
  const [categoryName, setCategoryName] = React.useState("");
  const [categorySize, setCategorySize] = React.useState("");
  const [catItem, setCatItem] = React.useState("");
  const [catMMItem, setCatMMItem] = React.useState("");
  const [page, setpage] = React.useState(1);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const classes = useStyles();
  const classe = useStyl();
  const classeHover = useHoever();
  const classSweet = useStyleSweet();
  const history = useHistory();

  const activeSession = sessionStorage.getItem("sessionData");

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { state } = useLocation();
  // console.log(state);

  useEffect(() => {
    let newSpli = state && state ? state.split(",") : "";
    // console.log(newSpli);
    axios
      .get(localApi + `/category/findById/${newSpli && newSpli[0]}`, {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      })
      .then((res) => {
        // console.log(res.data)
        const g = { label: res.data.title, value: res.data.title };
        // console.log(g);
        setCategoryName(g);
        setCatItem(res.data);
        setCategoryMM(res.data.parent);
      });

    axios
      .get(localApi + `/parent/findById/${newSpli && newSpli[1]}`, {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      })
      .then((res) => {
        // console.log(res.data)
        const g = { label: res.data.title, value: res.data.title };
        // console.log(g);
        setCategorySize(g);
        setCatMMItem(res.data);
      });

    var tempData = {};
    axios
      .get(
        localApi +
          `/image/list/byAdminAndCategoryAndParentId/${userObj && userObj.id}/${
            newSpli && newSpli[0]
          }/${newSpli && newSpli[1]}`,
        {
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        // setItemProd(res && res.data);
        const userUsed = res.data.map((d) => d?.id);
        Promise.all(
          userUsed.map(async (id) => {
            const usedApi = await fetch(
              localApi +
                `/productimage/find/byCountAdminAndImageId/${userObj.id}/${id}`,
              {
                method: "GET",
                headers: {
                  Authorization: `Basic ${credentials}`,
                },
              }
            );
            const data = await usedApi.json();
            // console.log(data);
            tempData = { ...tempData, [id]: data };
          })
        ).then(() => {
          // console.log(tempData);
          const tempItems2 = res.data.map((i) => {
            return { ...i, usedCount: tempData[i.id] || 0 };
          });
          setItemProd(tempItems2);
          // console.log(tempItems2);
        });
      });

    // setCategoryName(newSpli[0])
  }, []);

  async function getCat() {
    const api = localApi + `/category/list/byAdminId/${userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setCategory(getResult);
  }

  useEffect(() => {
    getCat();
    // catApi().then((res) => setCategory(res && res));
  }, []);

  const handleCategoryName = (categoryName) => {
    // console.log(categoryName);
    setCategoryName(categoryName);
    if (categoryName.value) {
      const catNameItem = category.find((f) => f.title == categoryName.value);
      setCategoryMM(catNameItem.parent);
      setCatItem(catNameItem && catNameItem);
    }
  };

  const handleCategorySize = (categorySize) => {
    setCategorySize(categorySize);
    if (categorySize.value) {
      var tempData = {};
      const catSizeItem = categoryMM.find((f) => f.title == categorySize.value);
      // console.log(catSizeItem);
      setCatMMItem(catSizeItem && catSizeItem);
      axios
        .get(
          localApi +
            `/image/list/byAdminAndCategoryAndParentId/${
              userObj && userObj.id
            }/${catItem.id}/${catSizeItem.id}`,
          {
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          // setItemProd(res && res.data);
          const userUsed = res.data.map((d) => d?.id);
          Promise.all(
            userUsed.map(async (id) => {
              const usedApi = await fetch(
                localApi +
                  `/productimage/find/byCountAdminAndImageId/${userObj.id}/${id}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: `Basic ${credentials}`,
                  },
                }
              );
              const data = await usedApi.json();
              // console.log(data);
              tempData = { ...tempData, [id]: data };
            })
          ).then(() => {
            // console.log(tempData);
            const tempItems2 = res.data.map((i) => {
              return { ...i, usedCount: tempData[i.id] || 0 };
            });
            setItemProd(tempItems2);
            console.log(tempItems2);
          });
        });
    }
  };

  useEffect(() => {
    setCategorySize("");
  }, [categoryName]);

  useEffect(() => {
    setItemProd("");
  }, [categorySize]);

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const handleDeleteDie = (prod) => {
    fetch(localApi + `/image/deleteImageById/${prod.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.error === true) {
          alertTypeOps("success");
          alertMsgOps("Deleted Successfully !!!");
          setSubmitted(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer
        justify="center"
        alignItems="center"
        style={{ marginBottom: "5%" }}
      >
        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          />
        ) : null}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AiOutlineFolderView />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>View Image</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer style={{ marginBottom: "3%" }}>
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Category Title
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    placeholder="Select Category Title..."
                    value={categoryName}
                    onChange={handleCategoryName}
                    options={
                      category &&
                      category.map((c) => {
                        return { label: c.title, value: c.title };
                      })
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Bangles Size MM
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    placeholder="Select Bangles Size MM..."
                    value={categorySize}
                    onChange={handleCategorySize}
                    options={
                      categoryMM &&
                      categoryMM.map((c) => {
                        return { label: c.title, value: c.title };
                      })
                    }
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* <InfiniteScroll
        dataLength={itemProd.length}
        next={fetchData}
        hasMore={true}
      > */}
      <GridContainer>
        {itemProd &&
          itemProd.map((prod) => (
            <GridItem xs={12} sm={4} md={2} key={prod.id}>
              <Card product className={classeHover.cardHover}>
                <CardHeader image className={classeHover.cardHeaderHover}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <ReactImageProcess
                      mode="waterMark"
                      waterMarkType="image"
                      waterMark={logo}
                      width={60}
                      height={60}
                      opacity={0.7}
                      // coordinate={[430, 200]}
                      coordinate={[100, 100]}
                      // coordinate={[[50,50],[50,50]]}
                    >
                      <img src={prod.cpanelPath} alt="..." />
                    </ReactImageProcess>
                  </a>
                </CardHeader>
                <CardBody>
                  <div className={classeHover.cardHoverUnder}>
                    {/* <Tooltip
                        id="tooltip-top"
                        title="View"
                        placement="bottom"
                        classes={{ tooltip: classeHover.tooltip }}
                      >
                        <Button color="transparent" simple justIcon>
                          <ArtTrack className={classeHover.underChartIcons} />
                        </Button>
                      </Tooltip> */}
                    <Tooltip
                      id="tooltip-top"
                      title="Edit"
                      placement="bottom"
                      classes={{ tooltip: classeHover.tooltip }}
                    >
                      <Button
                        color="success"
                        simple
                        justIcon
                        onClick={() =>
                          history.push({
                            pathname:
                              prod.originalImageUrl !== null
                                ? `/admin/updateCropImage`
                                : `/admin/updateImage`,
                            state: prod,
                          })
                        }
                      >
                        <Edit className={classeHover.underChartIcons} />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-top"
                      title="Remove"
                      placement="bottom"
                      classes={{ tooltip: classeHover.tooltip }}
                    >
                      <Button
                        color="danger"
                        simple
                        justIcon
                        onClick={() => {
                          handleDeleteDie(prod);
                        }}
                      >
                        <DeleteForeverIcon
                          className={classeHover.underChartIcons}
                        />
                      </Button>
                    </Tooltip>
                  </div>
                  {/* <h6 className={classeHover.cardProductTitle}>
                    {prod.imageName}
                  </h6> */}
                  <h6
                    className={classeHover.cardProductTitle}
                    style={{ color: "#d81b60" }}
                  >
                    {catItem.title + " " + catMMItem.title}
                    <br /> {"Design No. " + prod.id}
                  </h6>
                  <h6
                    className={classeHover.cardProductTitle}
                    // style={{ fontWeight: "bold" }}
                  >
                    {/* {prod.description} */}
                    <a
                      href={prod.cropImageUrl + "?id=" + prod.id}
                      target="_blank"
                    >
                      Download the Image
                    </a>{" "}
                    {"using  - " + prod.usedCount}
                  </h6>
                  <p className={classeHover.cardProductDesciprion}>
                    {/* {(prod.metalOption.readygram * prod.sets.pcs).toFixed(4)}{" "}
                      Avg Gram Of {prod.sets.setdesc} {prod.box.boxdesc}{" "}
                      {prod.ctndescription} */}
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
      {/* </InfiniteScroll> */}
    </div>
  );
}

ViewImage.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
